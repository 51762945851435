import React, { useState, useRef, useEffect } from "react";
// import { Search, ChevronDown, ChevronUp } from "lucide-react";
import { ChevronUpIcon, ChevronDownIcon, MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import { BASE_URL2 } from "../../utils/cookies.config";

const client = axios.create({
  baseURL: BASE_URL2,
});

const SearchableDropdown = ({ value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredRoutes, setFilteredRoutes] = useState([]);
  const dropdownRef = useRef(null);

  const getAllRoutes = async () => {
    setLoading(true);
    try {
      const res = await client.get("/aux/routes/search?query=" + searchTerm);
      setFilteredRoutes(res.data);
      setLoading(false);
    } catch (error) {
      console.log("get all routes error", error);
      setLoading(false);
    }
  };

  let debounceTimeout;
  useEffect(() => {
    debounceTimeout = setTimeout(() => {
      // setFilteredRoutes(routes.filter((route) => route.name.toLowerCase().includes(searchTerm.toLowerCase())));
      getAllRoutes();
    }, 1000);

    return () => clearTimeout(debounceTimeout);
  }, [searchTerm]);

  useEffect(() => {
    console.log("filteredRoutes", filteredRoutes);
  }, [filteredRoutes]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleSelect = (route) => {
    onChange(route);
    setIsOpen(false);
    setSearchTerm("");
  };

  return (
    <div
      className='relative'
      ref={dropdownRef}
    >
      <div
        className='w-full flex items-center justify-between px-4 py-2 border border-gray-300 rounded-lg cursor-pointer hover:border-green-500 focus-within:ring-2 focus-within:ring-green-500 focus-within:border-transparent'
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className={`flex-1 ${!value ? "text-gray-500" : "text-gray-900"}`}>{value || "Select route"}</span>
        {isOpen ? (
          <ChevronUpIcon className='w-5 h-5 text-gray-400' />
        ) : (
          <ChevronDownIcon className='w-5 h-5 text-gray-400' />
        )}
      </div>

      {isOpen && (
        <div className='absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-lg shadow-lg'>
          <div className='p-2 border-b border-gray-200'>
            <div className='relative'>
              <MagnifyingGlassIcon className='absolute left-3 top-1/2 transform -translate-y-1/2 w-4 h-4 text-gray-400' />
              <input
                type='text'
                className='w-full pl-9 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent'
                placeholder='Search routes...'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onClick={(e) => e.stopPropagation()}
              />
            </div>
          </div>

          <div className='max-h-60 overflow-y-auto'>
            {filteredRoutes.length > 0 ? (
              filteredRoutes.map((route, i) => (
                <div
                  key={route.uniquename + i}
                  className={`px-4 py-2 cursor-pointer hover:bg-gray-50 ${
                    value === route.name ? "bg-green-50 text-green-600" : "text-gray-700"
                  }`}
                  onClick={() => handleSelect(route.name)}
                >
                  {route.name}
                </div>
              ))
            ) : loading ? (<div className="w-full flex items-center justify-center py-8">
              <span className="animate-spin  w-6 h-6 border-2 border-transparent rounded-full" style={{ borderTopColor: '#10b981' }}></span>
            </div>) : (
              <div className='px-4 py-8 text-gray-500 text-center'>No routes found</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchableDropdown;
