import React, { useEffect } from "react";


const ios = "https://apps.apple.com/app/tap-and-go-ghana/id6444683769";
const android = "https://play.google.com/store/apps/details?id=com.itmsgh.tapngogh";

const App = () => {

  useEffect(() => {
    const agent = window.navigator.userAgent;
    const isMobile = agent.toLowerCase().includes("mobile");
    const isIphone = agent.toLowerCase().includes("iphone");
    const isAndroid = agent.toLowerCase().includes("android");
    if (isMobile && isIphone) {
      window.location.replace(ios);
    } else if (isMobile && isAndroid) {
      window.location.replace(android);
    } else {
      window.location.replace("/");
    }
  }, []);

  return null;
};

export default App;
