import axios from "axios";
import React, { useState } from "react";
import SearchableDropdown from "../../components/Feedback/SearchableDropdown";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import { BASE_URL2 } from "../../utils/cookies.config";

const client = axios.create({
  baseURL: BASE_URL2,
});

const FeedbackForm = () => {
  const [formData, setFormData] = useState({
    serviceType: "",
    otherService: "",
    complaintCategory: "",
    complaintType: "",
    otherComplaint: "",
    travelTime: "",
    contactMethod: "",
    contactDetails: "",
  });

  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");

  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Predefined options
  const complaintCategories = {
    card: {
      label: "Tap n Go Card Issues",
      options: ["Card not working", "Balance issues", "Card damaged", "Failed top-up", "Card registration issues"],
    },
    app: {
      label: "App Issues",
      options: ["App crash", "Payment failure", "Login problems", "Booking issues", "Route tracking issues"],
    },
    service: {
      label: "Service Quality",
      options: ["Driver behavior", "Vehicle condition", "Route deviation", "Schedule delays", "Safety concerns"],
    },
    other: {
      label: "Other Issues",
      options: ["Other"],
    },
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.serviceType || !formData.complaintCategory) {
      setError("Please fill in all required fields");
      return;
    }
    // Here you would typically send the data to your backend
    const payload = {
      ...formData,
      from,
      to,
      contactMethod: formData.contactMethod === "no contact needed" ? "none" : formData.contactMethod,
      complaintCategory: complaintCategories[formData.complaintCategory].label,
    };

    setIsLoading(true);
    try {
      const res = await client.post("/feedback", payload);
      setSubmitted(true);
      setError("");
      setIsLoading(false);
      window.scrollTo(0, 0);
    } catch (e) {
      console.log(e);
      setError(e?.response?.data);
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  if (submitted) {
    return (
      <div className='h-screen flex items-center justify-center'>
        <div className='text-center'>
          <CheckCircleIcon className='w-16 h-16 text-green-500 mx-auto mb-4' />
          <h2 className='text-2xl font-bold text-gray-900 mb-2'>Feedback Submitted Successfully!</h2>
          <p className='text-gray-600'>
            {formData.contactMethod !== "none"
              ? "We'll contact you via your preferred method soon."
              : "Thank you for helping us improve our services."}
          </p>
          <Link to='/'>
            <button className='mt-4 px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600'>
              Go to Home
            </button>
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className='max-w-3xl mx-auto px-4 py-12'>
      <div className='text-center mb-10'>
        <h1 className='text-3xl font-bold text-gray-900'>Tap n' Go Customer Feedback</h1>
        <p className='mt-3 text-gray-600'>Help us improve our services</p>
      </div>

      <form
        onSubmit={handleSubmit}
        className='space-y-6'
      >
        {error && (
          <div className='flex items-center gap-2 text-red-500 bg-red-50 p-3 rounded-lg'>
            <span>{error}</span>
          </div>
        )}

        <div className='grid grid-cols-1 gap-6'>
          <div>
            <label className='block text-sm font-medium text-gray-700 mb-1'>Type of Service *</label>
            <select
              name='serviceType'
              value={formData.serviceType}
              onChange={handleChange}
              className='w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent'
              required
            >
              <option value=''>Select a service</option>
              <option value='intercity'>Intercity</option>
              <option value='innercity'>Inner City (EV Bus, Trotro, Taxi)</option>
              <option value='railways'>Railways</option>
              <option value='other'>Other</option>
            </select>
          </div>

          {formData.serviceType === "other" && (
            <div>
              <label className='block text-sm font-medium text-gray-700 mb-1'>Please specify the service</label>
              <input
                type='text'
                name='otherService'
                value={formData.otherService}
                onChange={handleChange}
                className='w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent'
                required
              />
            </div>
          )}

          <div>
            <label className='block text-sm font-medium text-gray-700 mb-1'>Complaint Category *</label>
            <select
              name='complaintCategory'
              value={formData.complaintCategory}
              onChange={handleChange}
              className='w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent'
              required
            >
              <option value=''>Select category</option>
              {Object.entries(complaintCategories).map(([key, category]) => (
                <option
                  key={key}
                  value={key}
                >
                  {category.label}
                </option>
              ))}
            </select>
          </div>

          {formData.complaintCategory && (
            <div>
              <label className='block text-sm font-medium text-gray-700 mb-1'>Specific Issue *</label>
              <select
                name='complaintType'
                value={formData.complaintType}
                onChange={handleChange}
                className='w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent'
                required
              >
                <option value=''>Select specific issue</option>
                {complaintCategories[formData.complaintCategory].options.map((option) => (
                  <option
                    key={option}
                    value={option}
                  >
                    {option}
                  </option>
                ))}
              </select>
            </div>
          )}

          {formData.complaintType === "Other" && (
            <div>
              <label className='block text-sm font-medium text-gray-700 mb-1'>Please specify your issue</label>
              <input
                type='text'
                name='otherComplaint'
                value={formData.otherComplaint}
                onChange={handleChange}
                className='w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent'
                required
              />
            </div>
          )}

          <div>
            <label className='block text-sm font-medium text-gray-700 mb-1'>Time of Travel *</label>
            <input
              type='datetime-local'
              name='travelTime'
              value={formData.travelTime}
              onChange={handleChange}
              className='w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent'
              required
            />
          </div>

          <div>
            <label className='block text-sm font-medium text-gray-700 mb-1'>From *</label>
            <SearchableDropdown
              value={from}
              onChange={setFrom}
            />
          </div>
          <div>
            <label className='block text-sm font-medium text-gray-700 mb-1'>To *</label>
            <SearchableDropdown
              value={to}
              onChange={setTo}
            />
          </div>

          {formData.route === "Other" && (
            <div>
              <label className='block text-sm font-medium text-gray-700 mb-1'>Please specify your route</label>
              <input
                type='text'
                name='otherRoute'
                value={formData.otherRoute}
                onChange={handleChange}
                className='w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent'
                required
              />
            </div>
          )}

          <div>
            <label className='block text-sm font-medium text-gray-700 mb-1'>Preferred Contact Method *</label>
            <div className='grid grid-cols-2 md:grid-cols-4 gap-4'>
              {["Email", "Phone Call", "WhatsApp", "No Contact Needed"].map((method) => (
                <div
                  key={method}
                  className='flex items-center'
                >
                  <input
                    type='radio'
                    name='contactMethod'
                    value={method.toLowerCase()}
                    checked={formData.contactMethod === method.toLowerCase()}
                    onChange={handleChange}
                    className='w-4 h-4 text-green-500 border-gray-300 focus:ring-green-500'
                    required
                  />
                  <label className='ml-2 text-sm text-gray-700'>{method}</label>
                </div>
              ))}
            </div>
          </div>

          {formData.contactMethod && formData.contactMethod !== "no contact needed" && (
            <div>
              <label className='block text-sm font-medium text-gray-700 mb-1'>Contact Details *</label>
              <input
                type={formData.contactMethod === "email" ? "email" : "tel"}
                name='contactDetails'
                value={formData.contactDetails}
                onChange={handleChange}
                placeholder={`Enter your ${formData.contactMethod}`}
                className='w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent'
                required
              />
            </div>
          )}
        </div>

        <div className='mt-6 bg-gray-50 p-4 rounded-lg border border-gray-200'>
          <div className='flex gap-2'>
            {/* <Info className='w-5 h-5 text-gray-500 flex-shrink-0 mt-0.5' /> */}
            <p className='text-sm text-gray-600'>
              <strong>Privacy Notice:</strong> Your contact details will only be used to resolve your reported issue.
              Tap n' Go will not share your information with third parties.
            </p>
          </div>
        </div>

        {/* <div className='flex justify-center'> */}
        <button
          type='submit'
          className='mx-auto flex items-center justify-center w-32  px-6 py-2 bg-green-500 text-white font-medium rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed'
          disabled={isLoading}
          style={{
            textWrap: "nowrap",
          }}
        >
          {isLoading ? (
            <span
              className='animate-spin rounded-full h-5 w-5 border-2 border-transparent'
              style={{
                borderBottomColor: "white",
              }}
            ></span>
          ) : (
            <span>Submit Feedback</span>
          )}
        </button>
        {/* </div> */}
      </form>
    </div>
  );
};

export default FeedbackForm;
