// import Clients from "../screens/Dashboard/Users/Clients";

import About from '../screens/Dashboard/About';
import Innercity from '../screens/Dashboard/Innercity';
import Privacy from '../screens/Dashboard/Privacy';
import Terms from '../screens/Dashboard/Terms';
import Ticket from '../screens/Dashboard/Ticket';
import EVBuses from '../screens/Dashboard/EV/EVBuses';
import EVTrains from '../screens/Dashboard/EV/EVTrains';
import CustomerFeedback from '../screens/Dashboard/CustomerFeedback';
// import App from '../screens/Dashboard/App';

const { lazy } = require('react');

const Dashboard = lazy(() => import('../screens/Dashboard/index'));
const Faqs = lazy(() => import('../screens/Dashboard/Faqs'));
const Pricing = lazy(() => import('../screens/Dashboard/Pricing'));
const Clients = lazy(() => import('../screens/Dashboard/Users/Clients'));
const Drivers = lazy(() => import('../screens/Dashboard/Users/Drivers'));
const HowItWorks = lazy(() => import('../screens/Dashboard/HowItWorks'));
const Merchants = lazy(() => import('../screens/Dashboard/Merchants'));
const VehicleOwner = lazy(() =>
  import('../screens/Dashboard/Users/VehicleOwner')
);
const Developer = lazy(() => import('../screens/Dashboard/Developer'));
const RideHailing = lazy(() => import('../screens/Dashboard/RideHailing'));
const Intercity = lazy(() => import('../screens/Dashboard/Intercity'));
const Partners = lazy(() => import('../screens/Dashboard/Partners'));

const routes = [
  {
    path: '/',
    exact: true,
    component: Dashboard,
    name: 'Dashboard',
  },
  // {
  //   path: '/app',
  //   exact: true,
  //   component: App,
  //   name: 'App',
  // },
  {
    path: '/faqs',
    exact: true,
    component: Faqs,
    name: 'Faqs',
  },
  {
    path: '/pricing',
    exact: true,
    component: Pricing,
    name: 'Pricing',
  },
  {
    path: '/tickets/history',
    exact: true,
    component: Ticket,
    name: 'Ticket',
  },
  {
    path: '/rcpt',
    exact: true,
    component: Ticket,
    name: 'Ticket',
  },
  {
    path: '/users/commuter',
    exact: true,
    component: Clients,
    name: 'Clients',
  },
  {
    path: '/users/vehicleOwner',
    exact: true,
    component: VehicleOwner,
    name: 'VehicleOwner',
  },
  {
    path: '/users/drivers',
    exact: true,
    component: Drivers,
    name: 'Drivers',
  },
  {
    path: '/how-it-works',
    exact: true,
    component: HowItWorks,
    name: 'HowItWorks',
  },
  {
    path: '/merchants',
    exact: true,
    component: Merchants,
    name: 'Merchants',
  },
  {
    path: '/developer',
    exact: true,
    component: Developer,
    name: 'Developer',
  },

  {
    path: '/privacy',
    exact: true,
    component: Privacy,
    name: 'Privacy',
  },

  {
    path: '/terms',
    exact: true,
    component: Terms,
    name: 'Terms',
  },
  {
    path: '/about',
    exact: true,
    component: About,
    name: 'About',
  },
  {
    path: '/ride-hailing',
    exact: true,
    component: RideHailing,
    name: 'RideHailing',
  },
  {
    path: '/innercity',
    exact: true,
    component: Innercity,
    name: 'Innercity',
  },
  {
    path: '/intercity',
    exact: true,
    component: Intercity,
    name: 'Intercity',
  },
  {
    path: '/partners',
    exact: true,
    component: Partners,
    name: 'Partners',
  },
  {
    path: "/ev-buses",
    exact: true,
    component: EVBuses,
    name: "EVBuses",
  },
  {
    path: "/ev-trains",
    exact: true,
    component: EVTrains,
    name: 'EVTrains',
  },
  {
    path: '/customer-feedback',
    exact: true,
    component: CustomerFeedback,
    name: 'CustomerFeedback',
  },
];

export default routes;
